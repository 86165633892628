import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-mt-3" }
const _hoisted_2 = { class: "p-grid p-mt-1" }
const _hoisted_3 = { class: "p-col" }
const _hoisted_4 = { class: "p-col p-fluid" }
const _hoisted_5 = { class: "p-grid" }
const _hoisted_6 = { class: "p-col" }
const _hoisted_7 = { class: "p-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_Toolbar = _resolveComponent("Toolbar")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_InputText = _resolveComponent("InputText")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Toolbar, null, {
      left: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('companies.newCompany'),
          icon: "pi pi-plus",
          class: "p-mr-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newCompany()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('companies.saveCompany'),
          icon: "pi pi-save",
          class: "p-button-success",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveCompany()))
        }, null, 8, ["label"])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataTable, {
          value: _ctx.companies,
          class: "p-shadow-2",
          selection: _ctx.selectedCompany,
          "onUpdate:selection": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedCompany = $event)),
          selectionMode: "single",
          dataKey: "companyId",
          onRowSelect: _ctx.onRowSelect,
          scrollable: true,
          scrollHeight: "70vh"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "cui",
              header: _ctx.$t('companies.cui/cnp')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "companyName",
              header: _ctx.$t('companies.companyNameHeader')
            }, null, 8, ["header"])
          ]),
          _: 1
        }, 8, ["value", "selection", "onRowSelect"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("h3", null, _toDisplayString(_ctx.$t('companies.selectedCompanyHeader')), 1),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.$t('companies.companyNameHeader')) + " ", 1),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.editCompany.companyName,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.editCompany.companyName = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t('companies.cui/cnp')) + " ", 1),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.editCompany.cui,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.editCompany.cui = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ])
  ]))
}
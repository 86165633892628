import * as yup from 'yup';

export type CompanyMinimal={
    companyId:number,
    userId:number,
    companyName:string,
    cui:string
}

const companyMinimalSchema = yup.object().shape({
    companyId:yup.number().integer().notRequired(),
    userId:yup.number().integer().notRequired(),
    companyName:yup.string().required().trim(),
    cui:yup.string().required().trim()
})

export const validateCompany = async (x:CompanyMinimal)=>{
    
    if (await companyMinimalSchema.isValid(x)){
        return companyMinimalSchema.cast(x) as CompanyMinimal
    }
    else{
        throw Error("ss")
    }
}
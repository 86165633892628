
import { defineComponent } from "vue";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { onMounted, ref, Ref } from "vue";
import { addCompany, getCompanies, updateCompany } from "@/api";
import { CompanyMinimal, validateCompany } from "@/models/CompanyMinimal";

export default defineComponent({
  name: "Companies",
  components: {
    Button,
    Toolbar,
    DataTable,
    Column,
    InputText,
  },

  setup() {
    const companies: Ref<CompanyMinimal[]> = ref([]);
    const selectedCompany: Ref<CompanyMinimal> | Ref<{}> = ref({});
    const editCompany: Ref<CompanyMinimal> | Ref<{}> = ref({});

    const loadCompanies = async () => {
      companies.value = await getCompanies();
    };

    onMounted(async () => {
      loadCompanies();
    });

    const newCompany = () => {
      editCompany.value = {};
    };

    const saveCompany = async () => {
      try {
        const comp: CompanyMinimal = await validateCompany(editCompany.value as CompanyMinimal);
        if((comp.companyId as any) == undefined){
          await addCompany(comp.companyName, comp.cui)
        }else{
          await updateCompany(comp.companyId, comp.companyName, comp.cui);
        }
        editCompany.value = {};
      } catch (error) {
        console.log(error)
      }
      loadCompanies();
    };

    const onRowSelect = (event: {
      originalEvent: Event;
      data: CompanyMinimal;
      index: number;
      type: string;
    }) => {
      editCompany.value = { ...event.data };
    };

    return {
      companies,
      newCompany,
      saveCompany,
      selectedCompany,
      editCompany,
      onRowSelect,
    };
  },
});
